import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import i18n from '@/i18nVeeValidate'
import RequestsService from '../../services/requests'

/* eslint-disable no-magic-numbers */
// Initial state
const initialState = () => ({
	loading: false,
	requestFetched: false,
	requestRenewed: false,
	requestCanceled: false,
	request: null,
	error: null,
})
const state = initialState()

export const requestProps = `
	id
	isCargo
	requestedAt
	renewalLimitDate
`

// Getters
const getters = {}

// Actions
const actions = {
	async getRequest({ commit }, renewCode) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query RequestToRenew($renewCode: String!) {
			// 			requestToRenew(renewCode: $renewCode) {
			// 				${requestProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		renewCode,
			// 	},
			// })
			// /* eslint-disable-next-line */
			const response = await RequestsService.getRequestByUrlCode({ urlCode : renewCode })

			const request = response.data

			if (request) {
				commit('setRequest', request)
				commit('setRequestFetchedStatus', true)
			} else {
				commit('setError', i18n.t('error.noActiveRequestToRenew'))
			}
		} catch (e) {
			logger.error('Problem retrieving request', e)
			commit('setError', 'Sorry, something went wrong when trying to fetch your request.')
		} finally {
			commit('setLoading', false)
		}
	},
	async renew({ commit }, renewCode) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation RenewRequest($renewCode: String!) {
			// 			renewRequest(renewCode: $renewCode)
			// 		}
			// 	`,
			// 	variables: {
			// 		renewCode,
			// 	},
			// })
			/* eslint-disable-next-line */
			const response = await RequestsService.renewRequest({ renewCode })
			if (response.data) {
				commit('setRequestRenewedStatus', true)
			} else {
				commit('setError', i18n.t('error.noActiveRequestToRenew'))
			}
		} catch (e) {
			logger.error('Problem renewing request', e)
			commit('setError', 'Sorry, something went wrong when trying to renew your request.')
		} finally {
			commit('setLoading', false)
		}
	},
	async cancel({ commit }, renewCode) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation CancelRequest($renewCode: String!) {
			// 			cancelRequest(renewCode: $renewCode)
			// 		}
			// 	`,
			// 	variables: {
			// 		renewCode,
			// 	},
			// })
			// /* eslint-disable-next-line */
			const response = await RequestsService.cancelRequestByRenewCode({ renewCode})
			if (response.data) {
				commit('setRequestCanceledStatus', true)
			} else {
				commit('setError', i18n.t('error.noActiveRequestToCancel'))
			}
		} catch (e) {
			logger.error('Problem cancelling request', e)
			commit('setError', 'Sorry, something went wrong when trying to cancel your request.')
		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
const mutations = {
	setRequest(state, request) {
		state.request = request
	},
	setError(state, message) {
		state.error = message
	},
	setLoading(state, status) {
		state.loading = status
	},
	setRequestFetchedStatus(state, status) {
		state.requestFetched = status
	},
	setRequestRenewedStatus(state, status) {
		state.requestRenewed = status
	},
	setRequestCanceledStatus(state, status) {
		state.requestCanceled = status
	},
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
