import APIClient from '../api/apiClient'

const SubscriptionsService = {
	// Service to get all subscriptions
	getSubscriptions: (params) => {
		return APIClient.get(`/subscriptions/all-subscriptions`, { params })
	},

	// Service to get all subscription types
	getSubscriptionTypes: (params) => {
		return APIClient.get(`/subscriptions/all-subscription-types`, { params })
	},

	// Service to get all subscription type names
	getSubscriptionTypeNames: () => {
		return APIClient.get(`/subscriptions/all-subscription-type-names`)
	},

	// Service to get subscription by ID
	getSubscriptionById: (subscriptionid) => {
		return APIClient.get(`/subscriptions/subscription-operations/${subscriptionid}`)
	},

	// Service to update subscription by ID
	//update key and spot number
	updateSubscriptionById: (subscriptionid, payload) => {
		return APIClient.put(`/subscriptions/subscription-operations/${subscriptionid}`, payload)
	},

	// Service to send payment link by subscription ID
	sendPaymentLinkBySubscriptionId: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/send-payment-link`, payload)
	},

	// Service to verify coupon code by subscription ID
	verifyCouponCodeBySubscriptionId: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/verify-coupon-code`, payload)
	},

	// Service to resend payment link by subscription ID
	resendPaymentLinkBySubscriptionId: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/resend-payment-link`)
	},

	// Service to cancel subscription by ID
	cancelRequestSubscription: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/cancel-request`)
	},
	// Service to undo cancel subscription by ID
	undoCancelRequestSubscription: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/undo-cancel-request`)
	},
	// Service to undo cancel subscription by ID from admin
	undoCancelRequestSubscriptionForUser: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/undo-cancel`)
	},

	// Service to get subscription type by ID
	getSubscriptionTypeById: (subscriptiontypeid) => {
		return APIClient.get(`/subscriptions/subscriptions-type-operations/${subscriptiontypeid}`)
	},
	
	//Service to create charge for subscriptions based on subscriptionid
	createChargeForSubscription: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/create-charge`, payload)
	},	

	//Service to create key for subscriptions based on subscriptionid
	createKeyForSubscription: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/create-key`, payload)
	},

	//Service to refund for subscriptions based on subscriptionid
	refundForSubscription: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/refund`, payload)
	},

	//Service to start subscription based on subscriptionid
	startSubscription: (payload) => {
		return APIClient.post(`/subscriptions/subscription-operations` , payload)
	},
	completeSubscription: (subscriptionid , payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/complete` , payload)
	},
	//service to retry subscription
	retrySubscription: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/retry`)
	},
	//Service to update expiry date for subscriptions based on subscriptionid
	updateExpiryDateForSubscription: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/update-expiry-date`, payload)
	},
	//Service to refresh subscription based on subscriptionid
	refreshSubscription: (subscriptionid, payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/refresh` , payload)
	},
	//Service to cancel subscription based on subscriptionid
	cancelNowSubscription: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/cancel-now`)
	},
	//Service to Cancel Subscription by Admin
	cancelSubscriptionByAdmin: (subscriptionid) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/cancel`)
	},
	//Service to sync subscriptions with wow
	syncSubscriptionsWithWow: (payload) => {
		return APIClient.post(`/subscriptions/sync-with-wow`, payload)
	},
	//Service to get subscription by ID
	getSubscriptionToRetryPayment: (subscriptionid , payload) => {
		return APIClient.post(`/subscriptions/subscription-operations/${subscriptionid}/get-retry-payment` , payload)
	},
	//Service to initiate banccontact payment
	initiateBancontact:(payload) => {
		return APIClient.post(`/subscriptions/initiate-bancontact`,payload)
	},
	renewBancontact:(payload) => {
		return APIClient.post(`/subscriptions/renew-bancontact`,payload)
	},
	//Service to initiate stripe payment link
	initiateStripePaymentLink:(payload) => {
		return APIClient.post(`subscriptions/initiate-stripe-payment`,payload)
	},
	//Service to resend payment link for invoice item
	resendPaymentLinkInvoiceItem: (itemid) => {
		return APIClient.post(`/invoice-items/invoice-item-operations/${itemid}/resend-payment-link`)
	}

}

export default SubscriptionsService
