import APIClient from '../api/apiClient'

const ParkingsService = {
	// Service to get all active parkings
	getActiveParkings: (params) => {
		return APIClient.get(`/parkings/all-active-parkings`,{ params })
	},

	// Service to get all big parkings
	getBigParkings: (params) => {
		return APIClient.get(`/parkings/all-big-parkings`, {
			params,
		})
	},

	// Service to get all parkings
	getAllParkings: (params) => {
		return APIClient.get(`/parkings/all-parkings`, { params })
	},

	// Service to get all available parkings
	getAvailableParkings: (params) => {
		return APIClient.get(`/parkings/all-available-parkings`, { params })
	},

	// Service to get parking by ID
	getParkingById: (parkingid) => {
		return APIClient.get(`/parkings/active-parking-operations/${parkingid}`)
	},

	// Service to delete parking by ID
	deleteParkingById: (parkingid) => {
		return APIClient.delete(`/parkings/parking-operations/${parkingid}`)
	},

	// Service to get parking session config by parking ID
	getParkingConfigByParkingId: (parkingid) => {
		return APIClient.get(`/parkings/parking-operations/${parkingid}/parking-session-config`)
	},

	// Service to get active parking by ID
	getActiveParkingById: (parkingid) => {
		return APIClient.get(`/parkings/active-parking-operations/${parkingid}`)
	},

	// Service to get parking config by ID
	getParkingConfigById: (id) => {
		return APIClient.get(`/parkings/parking-config-operations/${id}`)
	},

	// Service to upsert parking session config
	upsertParkingConfig: (payload) => {
		return APIClient.post(`/parkings/parking-config-operations`, payload)
	},

	//Service to get requests by parking ID
	getRequestsByParkingId: (parkingid , payload) => {
		return APIClient.post(`/parkings/parking-operations/${parkingid}/requests` , payload)
	},

	// Service to create my parking
	upsertMyParking : (payload) => {
		return APIClient.post(`/parkings/upsert-for-user`, payload)
	},
	//Upsert parking from admin
	upsertParking : (payload) => {
		return APIClient.post(`/parkings/parking-operations`, payload)
	},
}

export default ParkingsService
