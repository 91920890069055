import APIClient from '../api/apiClient'

const TemporaryAccessService = {
    // Service to get all temporary accesses
    getParkingOrExistingTemporaryAccess: (params) => {
        return APIClient.get(`/temporary-access/parking/${params}`)
    },

    //Service to start a temporary access
    startTemporaryAccess: (payload) => {
        return APIClient.post(`/temporary-access/start`, payload)
    },
    //Service to end a temporary access
    stopTemporaryAccess: (id) => {
        return APIClient.post(`/temporary-access/${id}/stop`)
    },
    //Service to retry a temporary access
    retryTemporaryAccess: (id) => {
        return APIClient.post(`/api/v1/temporary-access/${id}/retry`)
    },
    //Service to get a temporary access
    getTemporaryAccess: (id , payload ) => {
        return APIClient.post(`/temporary-access/${id}`, payload)
    },
}
export default TemporaryAccessService
