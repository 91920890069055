import graphqlClient from '../../api/db'
import gql from 'graphql-tag'
import { findKey, reject } from 'lodash/fp'
import i18n from '@/i18nVeeValidate'
import { config, SUBSCRIPTION_STATES } from '@/config'
import { extractGraphqlError } from '@/helpers'
import SubscriptionsService from '../../services/subscriptions'
import { statusToString } from './admin/exports'

// Initial state
const initialState = () => ({
	all: [],
	current: {},
	direct: null,
	isLoading: false,
	sendInvoice: false,
	voucher: {
		name: null,
		status: '',
		reduction: null,
		isLoading: false,
		valid: false,
	},
	proposal: null,

	// useMobib: true,
})

const state = initialState()

export const subscriptionAdminProps = `
	id
	status
	paymentStatus
	remainingMonths
	start
	end
	paymentMethod
	createdAt
	updatedAt
	subscriptionType {
		name
	}
	parkingCode
	keyNumber
	stripeId
	mollieId
	spotNumber
	cancelRequestedAt
	personalCard {
		cardNumber
		cardType
	}
	deposits {
		id
		price
		status
		reimbursedAt
		incomedAt
		legacy
		paymentMethod
		stripeId
		mollieId
	}
	externalDeposit
	manualRefunds {
		status
		amount
		invoiceItemId
		depositId
		stripeId
		mollieId
		createdAt
		refundedAt
		description
	}
	invoiceItems {
		stripeId
		id
		status
		amount
		description
		type
		paid
		paidAt
		createdAt
		mollieId
		depositId
		refunds {
			status
			amount
			description
			invoiceItemId
			depositId
			stripeId
			mollieId
			createdAt
			refundedAt
		}
	}
	isCargo
`

export const subscriptionProps = `
		id
		status
		paymentStatus
		isPaymentLink
		start
		end
		subscriptionType {
			name
			duration
			price
		}
		parkingCode
		spotNumber
		parkingId
		scaPaymentIntentSecret
		cancelRequestedAt
		proposalId
	`
export const completeSubscriptionProps = `
	subscription{
		  id
		  status
		  paymentStatus
		  isPaymentLink
		  start
		  end
		  subscriptionType {
			  name
			  duration
			  price
		  }
		  parkingCode
		  spotNumber
		  parkingId
		  scaPaymentIntentSecret
		  cancelRequestedAt
		  proposalId
	  }
	  checkout
	  `

export const subscriptionName = (sub, locale = 'en') => {
	// const spotNumber = sub.spotNumber ? `- ${sub.spotNumber}` : ''

	return sub.parking?.code
		? `${sub.parking?.code} (${sub.subscriptionType.name})`
		: i18n.t('subscription.BigParking', locale)
}

export const addressName = (address, locale = 'en') => {
	return address[locale]
}

export const isExpired = ({ status, end }) => {
	return (
		parseInt(status, 10) === SUBSCRIPTION_STATES.active && new Date().setHours(0, 0, 0, 0) >= end
	)
}

export const subscriptionStatusName = ({ status, end }) => {
	// active: new, pending, active or expired
	const Status = statusToString(status)
	return isExpired({ Status, end })
		? 'expired'
		: findKey((v) => v === parseInt(Status, 10), SUBSCRIPTION_STATES)
}

export const subscriptionBikeType = (sub) => {
	return sub.isCargo ? 'Cargo' : 'Classic'
}

export const subsToShow = (subs) => {
	return reject((sub) => parseInt(sub.status, 10) === SUBSCRIPTION_STATES.new, subs)
}

// Getters
const getters = {
	subIsPending: (state) => {
		return state.current && state.current.status === SUBSCRIPTION_STATES.pending
	},
	subIsRetryableForPaymentLink: (state, _getters) => {
		return (
			_getters.subIsPending && state.current.isPaymentLink && state.current.scaPaymentIntentSecret
		)
	},
	isNewSubscription: (state) => {
		return state.current && state.current.status === SUBSCRIPTION_STATES.new
	},
}

// Actions

const actions = {
	// eslint-disable-next-line max-statements
	async completeSubscription({ commit, dispatch, state, rootGetters }, stripeToken) {
		commit('setLoading', true)

		const subscriptionId = state.current.id
		const proposalId = state.current.proposalId

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation CompleteSubscription($subscriptionId: Int!, $stripeToken: String!, $coupon: String, $childId: Int) {
			// 			completeSubscription(subscriptionId: $subscriptionId, stripeToken: $stripeToken, coupon: $coupon, childId: $childId) {
			// 				${completeSubscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		stripeToken,
			// 		coupon: state.voucher.name,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const payload = {
				stripeToken,
				coupon: state.voucher.name,
			}
			const response = await SubscriptionsService.completeSubscription(subscriptionId, payload)
			const { subscription: sub, checkout } = response.data

			if (sub) {
				dispatch('profile/addSubscription', sub, { root: true })

				commit('setCurrentSubscription', sub)

				if (proposalId) {
					commit('profile/acceptProposal', proposalId, { root: true })
				}

				return [true, checkout]
			} else {
				commit('setLoading', false)
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			//commit('setLoading', false)
		}
	},
	updateCurrentSubscriptioin({ commit, dispatch, state, rootGetters }, subscription) {
		const proposalId = state.current.proposalId
		if (subscription) {
			dispatch('profile/addSubscription', subscription, { root: true })

			commit('setCurrentSubscription', subscription)
			if (proposalId) {
				commit('profile/acceptProposal', proposalId, { root: true })
			}

			return true
		}
	},

	// eslint-disable-next-line max-statements
	async initiateBancontact({ commit, dispatch, state, rootGetters }, data) {
		commit('setLoading', true)

		const subscriptionId = state.current.id || data.subId

		// const proposalId = state.proposal ? state.proposal.id : null
		const redirectUrl = config.subscriptionRedirectUrl

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation InitiateBancontact(
			// 			$subscriptionId: Int!
			// 			$coupon: String
			// 			$redirectUrl: String
			// 			$language: String
			// 			$childId: Int
			// 		) {
			// 			initiateBancontact(
			// 				subscriptionId: $subscriptionId
			// 				coupon: $coupon
			// 				redirectUrl: $redirectUrl
			// 				language: $language
			// 				childId: $childId
			// 			){
			// 				${completeSubscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		coupon: state.voucher.name || data.coupon,
			// 		redirectUrl,
			// 		language: data.language,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const payload = {
				subscriptionId,
				coupon: state.voucher.name || data.coupon,
				redrictUrl : redirectUrl,
				language: data.language,
			}
			const childId = rootGetters['profile/childId']
			if (childId) {
				payload.childId = childId
			}
			const response = await SubscriptionsService.initiateBancontact(payload)
			const { subscripition: sub, url:checkout } = response.data

			if (sub) {
				dispatch('profile/addSubscription', sub, { root: true })

				commit('setCurrentSubscription', sub)

				// commit('resetState')

				return checkout
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},

	async initiateStripePaymentLink({ commit, dispatch, state, rootGetters }, language) {
		commit('setLoading', true)

		const subscriptionId = state.current.id

		// const proposalId = state.proposal ? state.proposal.id : null
		const redirectUrl = config.subscriptionRedirectUrl

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation InitiateStripePaymentLink(
			// 			$subscriptionId: Int!
			// 			$coupon: String
			// 			$redirectUrl: String
			// 			$language: String
			// 			$childId: Int
			// 		) {
			// 			initiateStripePaymentLink(
			// 				subscriptionId: $subscriptionId
			// 				coupon: $coupon
			// 				redirectUrl: $redirectUrl
			// 				language: $language
			// 				childId: $childId
			// 			) {
			// 				${subscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		coupon: state.voucher.name,
			// 		redirectUrl,
			// 		language,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const payload = {
				subscriptionId,
				redirectUrl,
				language,
				...(state.voucher.name && { coupon: state.voucher.name }),
				...(rootGetters['profile/childId'] && { childId: rootGetters['profile/childId'] })
			  };
			const response = await SubscriptionsService.initiateStripePaymentLink(payload)
			const sub = response.data

			if (sub) {
				dispatch('profile/addSubscription', sub, { root: true })

				commit('setCurrentSubscription', sub)

				// commit('resetState')
				return sub.scaPaymentIntentSecret
			} else {
				dispatch('alert/error', response.errors[0].message, { root: true })

				return false
			}
		} catch (error) {
			dispatch('alert/error', extractGraphqlError(error.message), { root: true })

			return false
		} finally {
			commit('setLoading', false)
		}
	},
	async subscribe(
		{ commit, dispatch, rootGetters },
		{ subscriptionTypeId, proposalId, withDeposit }
	) {
		// In a first phase there will only be one type of subscription (BigParking yearly)
		// Later we'll have to first fetch the list of subscriptionType and select one.
		// const subscriptionTypeId = 'default'
		// const proposalId = state.proposal ? state.proposal.id : null
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation Subscribe(
			// 			$subscriptionTypeId: String!
			// 			$proposalId: Int
			// 			$withDeposit: Boolean
			// 			$childId: Int
			// 		) {
			// 			subscribe(
			// 				subscriptionTypeId: $subscriptionTypeId
			// 				proposalId: $proposalId
			// 				withDeposit: $withDeposit
			// 				childId: $childId
			// 			) {
			// 				id
			// 				status
			// 				isPaymentLink
			// 				subscriptionType {
			// 					name
			// 					duration
			// 					price
			// 				}
			// 				proposalId
			// 				parkingId
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionTypeId,
			// 		proposalId,
			// 		withDeposit,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const payload = {
				subscriptionTypeName:subscriptionTypeId,
				withDeposit,
				...(proposalId && { proposalId }),
			}
			const response = await SubscriptionsService.startSubscription(payload)
			commit('startSubscription', response.data)
		} catch (error) {
			dispatch('alert/error', i18n.t(`flashMessage.${extractGraphqlError(error.message)}`), {
				root: true,
			})
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	initDirectSubscription({ commit }, payload) {
		commit('setDirectSubscription', payload)
	},

	// setVoucher({ commit }, payload) {
	// 	commit('setVoucher', payload.voucher)
	// },

	async validateVoucher({ state, commit, dispatch, rootGetters }) {
		commit('setVoucherLoading', true)

		const { name } = state.voucher
		const subscriptionId = state.current.id

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation ValidateCoupon($name: String!, $subscriptionId: Int!, $childId: Int) {
			// 			validateCoupon(name: $name, subscriptionId: $subscriptionId, childId: $childId) {
			// 				name
			// 				status
			// 				reduction
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		name,
			// 		subscriptionId,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			//Need to be tested
			const response = await SubscriptionsService.verifyCouponCodeBySubscriptionId(subscriptionId, {
				couponText : name,
			})
			// commit('setVoucher', )
			commit('setVoucherLoading', false)
			commit('validateVoucher', response.data)
		} catch (error) {
			commit('setVoucherLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// eslint-disable-next-line max-statements
	async refreshSubscription(
		{ commit, dispatch, state, rootGetters },
		{ subId, sendInvoiceForPaymentLink }
	) {
		commit('setLoading', true)

		const subscriptionId = subId || state.current.id

		const proposalId = state.current.proposalId

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation refreshSubscription($subscriptionId: Int!, $sendInvoiceForPaymentLink: Boolean, $childId: Int) {
			// 			refreshSubscription(subscriptionId: $subscriptionId, sendInvoiceForPaymentLink: $sendInvoiceForPaymentLink childId: $childId) {
			// 				${subscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		sendInvoiceForPaymentLink,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const payload = {
				"sendInvoiceForPaymentLink" : sendInvoiceForPaymentLink || false,
			}
			const response = await SubscriptionsService.refreshSubscription(subscriptionId, payload)
			// commit('setVoucher', )
			commit('setLoading', false)
			commit('setCurrentSubscription', response.data)
			dispatch('profile/addSubscription', response.data, { root: true })

			if (proposalId) {
				commit('profile/acceptProposal', proposalId, { root: true })
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// eslint-disable-next-line max-statements
	async getSubscriptionToRetryPayment(
		{ commit, dispatch, rootGetters },
		{ subscriptionId, proposalId }
	) {
		// log subscriptionId and proposalId
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation getSubscriptionToRetryPayment($subscriptionId: Int!, $proposalId: Int, $childId: Int) {
			// 			getSubscriptionToRetryPayment(subscriptionId: $subscriptionId, proposalId: $proposalId, childId: $childId) {
			// 				${subscriptionProps}
			// 				deposits {
			// 					id
			// 				}
			// 				coupon{
			// 					name
			// 					status
			// 					reduction
			// 				}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		proposalId,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			// proposalId : proposalId
			const response = await SubscriptionsService.getSubscriptionToRetryPayment(subscriptionId, {
				...(proposalId && { proposalId }),
			})

			// commit('setVoucher', )
			commit('setLoading', false)

			const sub = response.data
			
			if (sub) {
				commit('setCurrentSubscription', sub)
				dispatch('profile/addSubscription', sub, {
					root: true,
				})

				if (sub.coupon) {
					commit('validateVoucher', sub.coupon)
				}
			}
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// eslint-disable-next-line max-statements
	async cancelSubscription({ commit, dispatch, rootGetters }, subId) {
		commit('setLoading', true)

		const subscriptionId = subId

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation cancelSubscription($subscriptionId: Int!, $childId: Int) {
			// 			cancelSubscription(subscriptionId: $subscriptionId, childId: $childId) {
			// 				${subscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const response = await SubscriptionsService.cancelNowSubscription(subscriptionId)
			commit('setLoading', false)
			commit('setCurrentSubscription', response.data.cancelSubscription)
			dispatch('profile/addSubscription', response.data.cancelSubscription, { root: true })
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// eslint-disable-next-line max-statements
	async cancelRequestSubscription({ commit, dispatch, rootGetters }, subId) {
		commit('setLoading', true)

		const subscriptionId = subId

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation cancelRequestSubscription($subscriptionId: Int!, $childId: Int) {
			// 			cancelRequestSubscription(subscriptionId: $subscriptionId, childId: $childId) {
			// 				${subscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const response = await SubscriptionsService.cancelRequestSubscription(subscriptionId)

			commit('setLoading', false)
			commit('setCurrentSubscription', response.data)
			dispatch('profile/addSubscription', response.data, { root: true })
			dispatch('alert/success', i18n.t('flashMessage.cancelRequestDone'), { root: true })
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// eslint-disable-next-line max-statements
	async undoCancelRequestSubscription({ commit, dispatch, rootGetters }, subId) {
		commit('setLoading', true)

		const subscriptionId = subId

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation undoCancelRequestSubscription($subscriptionId: Int!, $childId: Int) {
			// 			undoCancelRequestSubscription(subscriptionId: $subscriptionId, childId: $childId) {
			// 				${subscriptionProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		subscriptionId,
			// 		childId: rootGetters['profile/childId'],
			// 	},
			// })
			const response = await SubscriptionsService.undoCancelRequestSubscription(subscriptionId)

			commit('setLoading', false)
			commit('setCurrentSubscription', response.data)
			dispatch('profile/addSubscription', response.data, {
				root: true,
			})
		} catch (error) {
			commit('setLoading', false)
			dispatch('alert/error', error.message, { root: true })
			throw error
		}
	},

	// setUseMobib({ commit }, payload) {
	// commit('setUseMobib', payload)
	// },
}

// Mutations
const mutations = {
	startSubscription(state, payload) {
		payload.status = statusToString(payload.status)
		state.current = Object.assign({}, state.current, payload)
	},

	setDirectSubscription(state, payload) {
		state.direct = payload.type
		state.voucher.name = payload.voucher
	},
	setVoucherName(state, voucher) {
		state.voucher.name = voucher
		state.voucher.status = ''
	},
	validateVoucher(state, { name, status, reduction }) {
		state.voucher = Object.assign({}, initialState().voucher, { name, reduction, status })
	},
	setVoucherLoading(state, loadingState) {
		state.voucher.isLoading = loadingState
	},
	setLoading(state, loadingState) {
		state.isLoading = loadingState
	},
	setSendInvoice(state) {
		state.sendInvoice = true
	},
	resetState(state) {
		Object.assign(state, initialState())
	},

	// selectProposalForSub(state, proposal) {
	// 	state.proposal = proposal
	// },
	setCurrentSubscription(state, sub) {
		sub.status = statusToString(sub.status)
		state.current = sub
	},

	// setUseMobib(state, payload) {
	// 	state.useMobib = payload
	// },
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
}
