import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import { parkingTypes, keyTypes } from '@/config'
import i18n from '@/i18nVeeValidate'
import { keys, findIndex, omit, sortBy } from 'lodash/fp'
import Vue from 'vue'
import { uploadFiles } from '../../../helpers'
import ParkingsService from '../../../services/parkings'

// import { sortBy, findIndex, find, filter, values, groupBy, flatten, map, min, compose } from 'lodash/fp'

// Initial state
const initialState = () => ({
	all: [],
	selectedParking: {},
	loading: false,
})

export const parkingProps = `
	id
	name
	code
	gateNames
	insideGateNames
	installationDate
	latitude
	longitude
	parkingTypeName
	capacityCargo
	capacityClassic
	parkingSpots {
	    id
	    amount,
	    name,
	    isCargo,
	    active
	}
	active
	keyType
	badgeAccess
	limitSubNumber
	isCycloparking
	isAllAccess
	legacyAddress {
		nl
		fr
	}
	comment {
		nl
		fr
		en
	}
	approved
	ownerId
	owner {
		id
		email
	}
	adminInfo
	sessionConfig {
		id
		parkingId
		perHourCost
		subscriptionFreeHours
	}
	images
`
function cleanPayload(data) {
    // Allowed properties for the main parking object
    const allowedFields = new Set([
        "id", "name", "parkingTypeName", "code", "keyType",
        "legacyAddressNl", "legacyAddressFr", "active",
        "parkingSpots", "isCycloparking", "badgeAccess",
        "longitude", "latitude", "installationDate",
        "sessionConfig", "images", "gateNames", "insideGateNames",
        "commentNl", "commentFr", "commentEn", "capacityClassic",
        "capacityCargo", "limitSubNumber", "adminInfo"
    ]);

    // Allowed properties for parkingSpots array
    const allowedParkingSpotFields = new Set([
        "id", "name", "isCargo", "active", "amount", "parkingId", "subscriptionTypeId"
    ]);

    // Allowed properties for sessionConfig object
    const allowedSessionConfigFields = new Set([
        "id", "subscriptionFreeHours", "perHourCost"
    ]);

    // Recursive function to clean nested objects
    function cleanObject(obj, allowedFieldsSet) {
        return Object.keys(obj).reduce((acc, key) => {
            if (allowedFieldsSet.has(key)) {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
    }

    // Clean the main payload object
    let cleanedData = cleanObject(data, allowedFields);

    // Clean parkingSpots array
    if (Array.isArray(cleanedData.parkingSpots)) {
        cleanedData.parkingSpots = cleanedData.parkingSpots.map(spot =>
            cleanObject(spot, allowedParkingSpotFields)
        );
    }

    // Clean sessionConfig object
    if (cleanedData.sessionConfig && typeof cleanedData.sessionConfig === "object") {
        cleanedData.sessionConfig = cleanObject(cleanedData.sessionConfig, allowedSessionConfigFields);
    }

    return cleanedData;
}

const state = initialState()

const getters = {
	orderedParkings: (state) => {
		return sortBy('code', state.all)
	},
	getParkingTypes: () => {
		return parkingTypes.map((type) => ({
			key: type,
			value: i18n.t(`parkings.parkingTypes.${type.toLowerCase()}`),
		}))
	},
	getKeyTypes: () => {
		return keys(keyTypes).map((type) => ({
			key: keyTypes[type],
			value: i18n.t(`parkings.keyTypes.${type}`),
		}))
	},
}

const actions = {
	async getParkings({ commit }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query allParkings {
			// 			allParkings {
			// 				${parkingProps}
			// 			}
			// 		}
			// 	`,
			// })
			const params = {
				offset: 0,
				limit: 0, // 0 means no limit
				sortkey: 'id',
				sortorder: 'ASC',
			}
			const response = await ParkingsService.getAllParkings(params)
			const parkings = response.data.parkings

			commit('setParkings', parkings)
		} catch (e) {
			logger.error('Problem retrieving parkings', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async deleteParking({ commit, dispatch }, id) {
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation deleteParking($id: Int!) {
			// 			deleteParking(id: $id)
			// 		}
			// 	`,
			// 	variables: {
			// 		id,
			// 	},
			// })
			const response = await ParkingsService.deleteParkingById(id)
			if (response.data) {
				commit('deleteParking', id)
				dispatch('alert/success', 'Parking successfully deleted', { root: true })

				return true
			} else {
				dispatch(
					'alert/error',
					"Parking can not be deleted because it has already been linked to subscriptions. Uncheck the 'active' flag to hide the parking instead.",
					{ root: true }
				)

				return false
			}
		} catch (e) {
			logger.error('parking creation error')
			dispatch('alert/error', e.message, { root: true })
			throw e
		}
	},

	async upsertParking({ commit, dispatch }, payload) {
		// console.log('creating parking', parking)

		commit('setLoading', true)

		let imagesUploaded = []
		if (payload.imagesToUpload) {
			imagesUploaded = await uploadFiles(payload.imagesToUpload)
			imagesUploaded = imagesUploaded.map(imageFile => imageFile.Location)
		}
		const parking = Object.assign({}, omit(['hasSessionParking', 'imagesToUpload'], payload), {
			parkingTypeName: payload.parkingTypeName.key,
			keyType: payload.keyType.key,
			latitude: parseFloat(payload.latitude),
			longitude: parseFloat(payload.longitude),
			limitSubNumber: parseInt(payload.limitSubNumber, 10),
			capacityCargo: parseInt(payload.capacityCargo, 10),
			capacityClassic: parseInt(payload.capacityClassic, 10),
			parkingSpots: payload.parkingSpots.map((ps) =>
				omit(
					'__typename',
					Object.assign({}, ps, {
						amount: parseFloat(ps.amount),
					})
				)
			),
			sessionConfig: payload.hasSessionParking? payload.sessionConfig : null,
			images: [...(payload.images || []), ...imagesUploaded],
		})
		// remove all null from parking object
		Object.keys(parking).forEach((key) => parking[key] === null && delete parking[key])
		const cleanedPayload = cleanPayload(parking);
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation upsertParking($parking: ParkingUpsertProps!) {
			// 			upsertParking(props: $parking) {
			// 				${parkingProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		parking,
			// 	},
			// })
			const response = await ParkingsService.upsertParking(cleanedPayload)
			if (response.data) {
				parking.id
					? commit('updateParking', response.data)
					: commit('createParking', { id: response.data, parking })

				return true
			}
		} catch (e) {
			logger.error('parking creation error')
			dispatch('alert/error', e.message, { root: true })
			throw e
		} finally {
			commit('setLoading', false)
		}

		return false
	},
}

const mutations = {
	setLoading(state, loadingState) {
		state.loading = loadingState
	},
	setParkings(state, parkings) {
		state.all = parkings
	},
	setSelectedParking(state, parking) {
		state.selectedParking = parking
	},
	createParking(state, { id, parking }) {
		state.all.push(Object.assign({}, parking, { id }))
	},
	updateParking(state, parking) {
		// state.all.push(parking)
		const idx = findIndex({ id: parking.id }, state.all)

		if (idx >= 0) {
			// Vue.set(state.all, idx, parking)
			Vue.set(state.all, idx, Object.assign({}, state.all[idx], parking))
		}
	},
	deleteParking(state, parkingId) {
		const idx = findIndex({ id: parkingId }, state.all)

		if (idx >= 0) {
			Vue.delete(state.all, idx)
		}
	},
}

export default {
	state,
	getters,
	actions,
	mutations,
}
