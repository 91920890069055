import APIClient from '../api/apiClient'

const ReportsServices = {
	getDepositReport: () => {
		return APIClient.get(`/reports/deposit`)
	},
	getIncomeReport: () => {
		return APIClient.get(`/reports/income`)
	},
	getProrationReport:(payload) => {
		return APIClient.post(`/reports/proration`, payload)
	},
	getInventoryReport: () => {
		return APIClient.get(`/reports/inventory`)
	},
}

export default ReportsServices
