<template>
	<div class="subscription-wrapper">
		<Loading v-if="!data.isLoaded" />
		<div v-if="currentSubscription.paymentStatus === '3'">
			<div class="subscription-title-section">
				<img class="subscription-status-image" src="../../assets/images/succes-logo.png" alt="" />
				<h1 class="title">
					{{ $t('subscription.successTitle') }}
				</h1>
			</div>
			<div class="subscription-content">
				{{ $t('subscription.succesInfoStep3') }}
			</div>
		</div>
		<div v-else-if="sendInvoice">
			<div class="subscription-title-section">
				<h1 class="title">
					{{ $t('subscription.activationPendingTitle') }}
				</h1>
			</div>
			<div class="subscription-content">
				{{ $t('subscription.pendingInfoStep3Invoice') }}
			</div>
		</div>
		<div v-else-if="currentSubscription.paymentStatus === '2' && isLoading">
			<div class="subscription-title-section">
				<div class="subscription-pending-image">
					<Loading :transparent="true" text="Please wait" positionClass="absolute" />
				</div>
				<h1 class="title">
					{{ $t('subscription.pendingTitle') }}
				</h1>
			</div>
		</div>
		<div v-else-if="currentSubscription.paymentStatus === '2'">
			<div class="subscription-title-section">
				<h1 class="title">
					{{ $t('subscription.activationPendingTitle') }}
				</h1>
			</div>
			<div class="subscription-content">
				{{ $t('subscription.pendingInfoStep3') }}
			</div>
		</div>
		<div v-else>
			<div class="subscription-title-section">
				<div class="subscription-pending-image">
					<Loading :transparent="true" text="Please wait" positionClass="absolute" />
				</div>
				<h1 class="title">
					{{ $t('subscription.successTitle') }}
				</h1>
			</div>
			
		</div>
		<div class="subscription-actions">
			<div class="subscription-actions-row">
				<router-link  class="button blue" to="/profile">
					{{ $t('general.profileLink') }}
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex'

export default {
	name: 'Step3',
	components: {
		Loading,
	},
	props: {
		data: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	data: () => ({}),
	computed: {
		...mapState('subscription', {
			currentSubscription: (state) => state.current,
			isLoading: (state) => state.isLoading,
			sendInvoice: (state) => state.sendInvoice,
		}),
		...mapGetters('profile', {
			getSubscription: 'getSubscription',
		}),
		...mapGetters('subscription', {
			subIsPending: 'subIsPending',
		}),
	},

	mounted() {
		if (!this.sendInvoice) {
			this.refreshSubscription({})
		}
	},
	beforeDestroy() {
		// this.resetState()
	},
	methods: {
		switchStep() {
			this.$emit('switch-step')
		},
		...mapMutations('subscription', {
			setCurrentSubscription: 'setCurrentSubscription',
			setLoading: 'setLoading',
			resetState: 'resetState',
		}),
		...mapActions('subscription', {
			refreshSubscription: 'refreshSubscription',
		}),
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/subscription/step3';
.subscription-content {
	text-align: center;
}
.subscription-actions-row {
	justify-content: center;
}
.subscription-pending {
	position: relative;
}
</style>
