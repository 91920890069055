/* eslint-disable  max-statements */
import graphqlClient from '@/api/db'
import gql from 'graphql-tag'
import { logger } from '@/logger'
import { findIndex, find, findKey, flatMap } from 'lodash/fp'
import Vue from 'vue'
import { genericMutation } from '@/api/helpers'
import { subscriptionAdminProps } from '../subscription'
import { USER_STATES, REQUEST_STATES } from '@/config'
import AuthService from '../../../services/auth'
import UserService from '../../../services/userService'
import { processUserPayload } from './exports'
import RequestsService from '../../../services/requests'
import SubscriptionsService from '../../../services/subscriptions'

// Initial state
const initialState = () => ({
	all: [],
})

export const userStatusName = (id) => {
	return findKey((v) => v === parseInt(id, 10), USER_STATES)
}

const parentAndChildObjects = `
historyLogs {
	action
	createdAt
}
subscriptions {
	${subscriptionAdminProps}
}
requests {
	id
	type
	comment
	requestedAt
	status
	addressId
	address {
		addressType
		streetName
		houseNumber
		id
		streetName
		houseNumber
		busNumber
	}
	userId
}
proposals {
	id
	status
	createdAt
	lastStatusChangedAt
	comment
	parking {
		id
		code
	}
	request {
		id
	}
}
`

export const adminUserProps = `
		id
		firstName
		lastName
		email
		phoneNumber
		language
		newsletter
		mobib
		status
		legacyAddress
		bankAccount
		parentId
		parent {
			email
		}
		${parentAndChildObjects}
		addresses {
			id
			addressType
			streetName
			houseNumber
			busNumber
			city
			postalCode
			realAddress
		}
		children {
			id
			firstName
			lastName
			mobib
			status
			parentId
			${parentAndChildObjects}
		}
		admin
		company {
			name
			vatNumber
			address {
				id
				addressType
				city
				postalCode
				streetName
				houseNumber
				busNumber
				titularLine
			}
		}
		createdAt
	`

const state = initialState()

const getters = {}

const actions = {
	async findUsers({ commit }, query) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.query({
			// 	query: gql`
			// 		query FindUsers($query: String!) {
			// 			findUsers(query: $query) {
			// 				${adminUserProps}
			// 			}
			// 		}
			// 	`,
			// 	variables: {
			// 		query,
			// 	},
			// })
			const params = { 
				offset: 0,
				limit: query === 'bigparking'  ? 0 : 1000,
				sortkey : 'id',
				sortorder : 'asc',
				search: query
			}
			const response = await UserService.getUsers(params)
			const transformedUsers = processUserPayload(response.data.users)
			commit('findUsers', transformedUsers)
			commit('admin/resetUserForm', null, { root: true })
		} catch (e) {
			logger.error('Problem retrieving requested users', e)
		} finally {
			commit('setLoading', false)
		}
	},

	async deleteUser({ commit, dispatch }, { userId }) {
		// const response = await genericMutation(
		// 	{ commit, dispatch },
		// 	{
		// 		mutation: gql`
		// 			mutation DeleteUser($userId: Int) {
		// 				deleteUser(userId: $userId)
		// 			}
		// 		`,
		// 		variables: { userId },
		// 	}
		// )
		const response = await UserService.deleteUser(userId)
		if (response?.data?.deleteUser === false) {
			// commit('deleteUser')
			dispatch(
				'alert/error',
				'Delete user failed, please make sure there are no active subscriptions',
				{ root: true }
			)
		} else {
			commit('admin/resetUserForm', null, { root: true })
			dispatch('alert/success', 'User successfully deleted', { root: true })
		}
	},

	async updateMobibForUser({ commit, dispatch }, { cardNumber, userId }) {
		// const response = await genericMutation(
		// 	{ commit, dispatch },
		// 	{
		// 		mutation: gql`
		// 			mutation UpdateMobibForUser($cardNumber: String!, $userId: Int) {
		// 				updateMobibForUser(cardNumber: $cardNumber, userId: $userId)
		// 			}
		// 		`,
		// 		variables: { cardNumber, userId },
		// 	}
		// )
		const response = await UserService.updateMobibForUser(userId, {mobib: cardNumber})
		if (response.data.updateMobibForUser) {
			commit('updateMobib', { mobib: '', userId })
			dispatch('alert/success', 'MOBIB updated', { root: true })
		} else {
			dispatch('alert/error', 'MOBIB update failed', { root: true })
		}
	},

	async updateBankAccountForUser({ commit, dispatch }, { bankAccount, userId }) {
		// const response = await genericMutation(
		// 	{ commit, dispatch },
		// 	{
		// 		mutation: gql`
		// 			mutation UpdateBankAccountForUser($bankAccount: String!, $userId: Int) {
		// 				updateBankAccountForUser(bankAccount: $bankAccount, userId: $userId)
		// 			}
		// 		`,
		// 		variables: { bankAccount, userId },
		// 	}
		// )
		const response = await AuthService.updateUserBankAccount({ bankAccount, userId })
		if (!response.data.errors) {
			commit('updateBankAccount', { bankAccount: response.data.updateBankAccountForUser, userId })
			dispatch('alert/success', 'Bank Account number updated', { root: true })
		} else {
			dispatch('alert/error', 'Bank Account number update failed', { root: true })
		}

		return response.data
	},

	async updatePhoneNumberForUser({ commit, dispatch }, { phoneNumber, userId }) {
		const response = await genericMutation(
			{ commit, dispatch },
			{
				mutation: gql`
					mutation UpdatePhoneNumberForUser($phoneNumber: PhoneNumber!, $userId: Int) {
						updatePhoneNumberForUser(phoneNumber: $phoneNumber, userId: $userId)
					}
				`,
				variables: { phoneNumber, userId },
			}
		)

		if (response.data.updatePhoneNumberForUser) {
			commit('updatePhone', { phone: phoneNumber, userId })
			dispatch('alert/success', 'Phone number updated', { root: true })
		} else {
			dispatch('alert/error', 'Phone update failed, exists already?', { root: true })
		}
	},

	async updateEmailForUser({ commit, dispatch }, { email, userId }) {
		// const response = await genericMutation(
		// 	{ commit, dispatch },
		// 	{
		// 		mutation: gql`
		// 			mutation UpdateEmailForUser($email: String!, $userId: Int) {
		// 				updateEmailForUser(email: $email, userId: $userId)
		// 			}
		// 		`,
		// 		variables: { email, userId },
		// 	}
		// )
		const response = await UserService.updateUserEmail(userId, {email})

		if (response.data.updateEmailForUser) {
			commit('updateEmail', { email, userId })
			dispatch('alert/success', 'Email address updated', { root: true })
		} else {
			dispatch('alert/error', 'Email address update failed, exists already?', { root: true })
		}
	},

	async updateNewsletterForUser({ commit, dispatch }, { newsletter, userId }) {
		const response = await genericMutation(
			{ commit, dispatch },
			{
				mutation: gql`
					mutation UpdateNewsletterForUser($newsletter: Boolean!, $userId: Int) {
						updateNewsletterForUser(newsletter: $newsletter, userId: $userId)
					}
				`,
				variables: { newsletter, userId },
			}
		)

		if (response.data.updateNewsletterForUser) {
			commit('updateNewsletter', { newsletter, userId })
			dispatch('alert/success', 'Newsletter updated', { root: true })
		} else {
			dispatch('alert/error', 'Newsletter update failed', { root: true })
		}
	},
	async updateLanguageForUser({ commit, dispatch }, { language, userId }) {
		const response = await genericMutation(
			{ commit, dispatch },
			{
				mutation: gql`
					mutation UpdateLanguageForUser($language: String!, $userId: Int) {
						updateLanguageForUser(language: $language, userId: $userId)
					}
				`,
				variables: { language, userId },
			}
		)

		if (response.data.updateLanguageForUser) {
			commit('updateLanguage', { language, userId })

			// commit('setUserFormLanguage', language)
			dispatch('alert/success', 'Language updated', { root: true })
		} else {
			dispatch('alert/error', 'Language update failed', { root: true })
		}
	},

	async updateExpiryDateForSub({ commit, dispatch }, payload) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation UpdateExpiryDateForSub($expiryDate: Date!, $subId: Int) {
			// 			updateExpiryDateForSub(expiryDate: $expiryDate, subId: $subId)
			// 		}
			// 	`,
			// 	variables: {
			// 		expiryDate: payload.expiryDate,
			// 		subId: payload.subId,
			// 	},
			// })

			const response = await SubscriptionsService.updateExpiryDateForSubscription(payload.subId, { expiryDate: payload.expiryDate })
			commit('updateExpiryDate', { expiryDate: response.data.updateExpiryDateForSub, payload })
			dispatch('alert/success', 'Expiry date updated', { root: true })

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async deleteRequest({ commit, dispatch, rootState }, { requestId }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation deleteParkingRequest($id: Int!, $childId: Int) {
			// 			deleteParkingRequest(id: $id, childId: $childId)
			// 		}
			// 	`,
			// 	variables: {
			// 		id: requestId,
			// 		childId: getters.childId,
			// 	},
			// })
			const response = await RequestsService.deleteParkingRequest(requestId)
			if (response.status == 200) {
				commit('deleteRequest', {
					requestId,
					userId: rootState.admin.userForm.user.id,
				})
				dispatch('alert/success', 'Request deleted', { root: true })
			} else {
				dispatch('alert/error', 'Request could not be deleted', { root: true })
			}

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async updateRequestDate({ commit, dispatch, rootState }, { requestDate, requestId }) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation updateRequestDate($requestDate: Date!, $requestId: Int) {
			// 			updateRequestDate(requestDate: $requestDate, requestId: $requestId)
			// 		}
			// 	`,
			// 	variables: {
			// 		requestDate,
			// 		requestId,
			// 	},
			// })
			const response = await RequestsService.updateRequestById(requestId, { requestedAt: requestDate })

			commit('updateRequestDate', {
				requestDate: Date.parse(response.data.requestedAt),
				requestId,
				userId: rootState.admin.userForm.user.id,
			})
			dispatch('alert/success', 'Request date updated', { root: true })

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async updateSpotNumber({ commit, dispatch }, payload) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation updateSpotNumber($spotNumber: Int, $subId: Int!) {
			// 			updateSpotNumber(spotNumber: $spotNumber, subId: $subId)
			// 		}
			// 	`,
			// 	variables: {
			// 		spotNumber: payload.spotNumber,
			// 		subId: payload.subId,
			// 	},
			// })
			const response = await SubscriptionsService.updateSubscriptionById(payload.subId, { spotNumber: payload.spotNumber , keyNumber: ''})

			commit('updateSpotNumber', { spotNumber: response.data.spotNumber, payload })
			dispatch('alert/success', 'Spotnumber updated', { root: true })

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},

	async updateKeyForSub({ commit, dispatch }, payload) {
		commit('setLoading', true)

		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: gql`
			// 		mutation UpdateKeyForSub($keyNumber: String!, $subId: Int!) {
			// 			updateKeyForSub(keyNumber: $keyNumber, subId: $subId)
			// 		}
			// 	`,
			// 	variables: {
			// 		keyNumber: payload.keyNumber,
			// 		subId: payload.subId,
			// 	},
			// })
			const response = await SubscriptionsService.updateSubscriptionById(payload.subId, { keyNumber: payload.keyNumber, spotNumber: 0 })
			commit('updateKeyForSub', { keyNumber: response.data.keyNumber, payload })
			dispatch('alert/success', 'Subscription key updated', { root: true })

			return true
		} catch (error) {
			dispatch('alert/error', error.message, { root: true })
			throw error
		} finally {
			commit('setLoading', false)
		}
	},
}

const mutations = {
	findUsers(state, users) {
		state.all = users
	},
	updateFindUser(state,user) {
		const index = findIndex((u) => u.id === user.id, state.all)
		if (index >= 0) {
			Vue.set(state.all, index, user)
		}
	},
	updateMobib(state, { mobib, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'mobib', mobib)
		}
	},
	updateBankAccount(state, { bankAccount, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'bankAccount', bankAccount)
		}
	},
	updateEmail(state, { email, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'email', email)
		}
	},
	updatePhone(state, { phone, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'phoneNumber', phone)
		}
	},

	updateNewsletter(state, { newsletter, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'newsletter', newsletter)
		}
	},
	updateLanguage(state, { language, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			Vue.set(state.all[index], 'language', language)
		}
	},

	updateExpiryDate(state, { expiryDate, payload }) {
		const index = findIndex((user) => user.id === payload.userId, state.all)

		if (index >= 0) {
			const subscription = find((sub) => sub.id === payload.subId, state.all[index].subscriptions)

			Vue.set(subscription, 'end', expiryDate)
		}
	},
	updateRequestDate(state, { requestDate, requestId, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			const request = find((req) => req.id === requestId, state.all[index].requests)

			Vue.set(request, 'requestedAt', requestDate)
		}
	},

	deleteRequest(state, { requestId, userId }) {
		const index = findIndex((user) => user.id === userId, state.all)

		if (index >= 0) {
			let request = find((req) => req.id === requestId, state.all[index].requests)

			if (!request) {
				const childReqs = flatMap((c) => c.requests, state.all[index].children)

				request = find((req) => req.id === requestId, childReqs)
			}

			Vue.set(request, 'status', REQUEST_STATES.deleted)
		}
	},
	updateKeyForSub(state, { keyNumber, payload }) {
		const index = findIndex((user) => user.id === payload.userId, state.all)

		if (index >= 0) {
			let subscription = find((sub) => sub.id === payload.subId, state.all[index].subscriptions)

			if (!subscription) {
				const childSubs = flatMap((c) => c.subscriptions, state.all[index].children)

				subscription = find((sub) => sub.id === payload.subId, childSubs)
			}

			Vue.set(subscription, 'keyNumber', keyNumber)
		}
	},

	updateSpotNumber(state, { spotNumber, payload }) {
		const index = findIndex((user) => user.id === payload.userId, state.all)

		if (index >= 0) {
			let subscription = find((sub) => sub.id === payload.subId, state.all[index].subscriptions)

			if (!subscription) {
				const childSubs = flatMap((c) => c.subscriptions, state.all[index].children)

				subscription = find((sub) => sub.id === payload.subId, childSubs)
			}

			Vue.set(subscription, 'spotNumber', spotNumber)
		}
	},

	// updateDepositForSub(state, { subscription, userId }) {
	// 	const index = findIndex(user => user.id === userId, state.all)

	// 	if (index >= 0) {
	// 		const sub = find((sub) => sub.id === subscription.id, state.all[index].subscriptions)

	// 		Vue.set(sub.deposit, 'deposit', subscription.deposit)
	// 	}
	// },
}

export default {
	state,
	getters,
	actions,
	mutations,
}
