<template>
	<div class="profile-subscriptions-profile">
		<Loading v-if="isLoading" />
		<!-- <div class="profile-title-section">
			<h1 class="title profile-title">
				{{ $t('profile.titleSubscriptions') }}
			</h1>
		</div> -->
		<p v-if="subscriptions.length <= 0" class="profile-subscriptions-profile-no-data">
			{{ $t('profile.subscription.noSubscription') }}
		</p>
		<div v-else-if="subscriptions.length > 0" class="profile-subscriptions-profile-table">
			<!-- <div class="profile-subscriptions-profile-table-header">
				<div class="profile-subscriptions-profile-table-header-item size30">
					{{ $t('subscription.overviewHeaders.name') }}
				</div>
				<div class="profile-subscriptions-profile-table-header-item size10">
					{{ $t('subscription.overviewHeaders.spotNumber') }}
				</div>
				<div class="profile-subscriptions-profile-table-header-item size20">
					{{ $t('subscription.overviewHeaders.expiryDate') }}
				</div>
				<div class="profile-subscriptions-profile-table-header-item size20">
					{{ $t('subscription.overviewHeaders.status') }}
				</div>
				<div class="profile-subscriptions-profile-table-header-item actions" />
			</div> -->
			<div
				v-for="sub in subscriptions"
				:key="sub.id"
				class="profile-subscriptions-profile-table-content"
			>
				<div class="profile-subscriptions-profile-table-content-row">
					<div class="profile-subscriptions-profile-table-content-item address-subscription">
						<img
							src="../../assets//images/session/icon_location_bigparking.svg"
							alt="Add Circle Icon"
							style="margin-right: 4px; margin-bottom: auto"
						/>
						<div>
							<span
								>{{ sub.parking ? sub.parking.name : '' }}*{{
									subscriptionName(sub, $i18n.locale)
								}}</span
							>
							<div
								class="profile-subscriptions-profile-table-content-item address-subscription-address"
							>
								{{ sub.parking ? addressName(sub.parking.legacyAddress, $i18n.locale) : '' }}
							</div>
						</div>
					</div>
				</div>
				<!-- <div class="profile-subscriptions-profile-table-content-row">
					<div class="profile-subscriptions-profile-table-content-item">
						<label class="profile-subscriptions-profile-table-content-item label">SPOT</label>
						<div class="profile-subscriptions-profile-table-content-item text">
							{{ sub.spotNumber }}
						</div>
					</div>
				</div> -->
				<div class="profile-subscriptions-profile-table-content-row">
					<div class="profile-subscriptions-profile-table-content-item spot-number">
						<label class="profile-subscriptions-profile-table-content-item label">
							{{ $t('subscription.overviewHeaders.spotNumber') }}
						</label>
						<div class="profile-subscriptions-profile-table-content-item text">
							{{ sub.spotNumber }}
						</div>
					</div>
				</div>

				<div class="profile-subscriptions-profile-table-content-row">
					<div class="profile-subscriptions-profile-table-content-item">
						<label class="profile-subscriptions-profile-table-content-item label">
							{{ $t('subscription.overviewHeaders.status') }}
						</label>
						<div class="profile-subscriptions-profile-table-content-item text">
							{{ $t(`subscription.statuses.${subscriptionStatusName(sub)}`) }}
						</div>
					</div>
					<div class="profile-subscriptions-profile-table-content-item">
						<label class="profile-subscriptions-profile-table-content-item label">{{
							$t('subscription.overviewHeaders.dueDate')
						}}</label>
						<div class="profile-subscriptions-profile-table-content-item text">
							{{ date(sub.end) }}
						</div>
					</div>
				</div>
				<div class="profile-subscriptions-profile-table-content-row">
					<div class="profile-subscriptions-profile-table-content-item" style="width: auto">
						<label class="profile-subscriptions-profile-table-content-item label">{{
							$t('subscription.overviewHeaders.bikeLabel')
						}}</label>
						<div class="profile-subscriptions-profile-table-content-row-sm">
							<label>1</label>
							<div
								:class="`profile-subscriptions-profile-table-content-item-icon ${
									sub.isCargo ? 'cargo' : 'classic'
								}`"
							/>
						</div>
					</div>
					<!-- <div class="profile-subscriptions-profile-table-content-item"> -->
					<span class="profile-subscriptions-profile-table-content-item velo">
						{{
							sub.isCargo
								? $t('subscription.overviewHeaders.typeCargo')
								: $t('subscription.overviewHeaders.typeClassic')
						}}
					</span>
					<!-- </div> -->
				</div>
				<div class="profile-subscriptions-profile-table-content-row">
					<label class="profile-subscriptions-profile-table-content-item text price-box">{{
						$t('subscription.overviewHeaders.total')
					}}</label>
					<div class="profile-subscriptions-profile-table-content-item">
						<!-- {{ date(sub.end) }} -->
						<span class="profile-subscriptions-profile-table-content-item price"
							>€{{ parseInt(sub.subscriptionType.price) }}</span
						>
						<span class="profile-subscriptions-profile-table-content-item priceLabel"
							>/{{ $t('subscription.overviewHeaders.year') }}</span
						>
					</div>
				</div>
				<!-- <div class="profile-subscriptions-profile-table-content-item size20">
					{{ $t(`subscription.statuses.${subscriptionStatusName(sub)}`) }}
				</div> -->
				<div class="profile-subscriptions-profile-table-content-row">
					<div
						v-if="lastPaidInvoice(sub.id)"
						class="profile-subscriptions-profile-table-content-item profile-subscriptions-profile-action left"
					>
						<!-- <a :href="`${invoiceUrl}?itemId=${lastPaidInvoiceItem[sub.id]}`"> Invoice </a> -->
						<a @click="downloadInvoice(lastPaidInvoiceItem[sub.id])"> Invoice </a>
					</div>
					<div
						v-if="isPending(sub)"
						class="profile-subscriptions-profile-table-content-item profile-subscriptions-profile-action right"
						@click="doRefreshSubscription(sub.id)"
					>
						{{ $t('profile.subscription.retryButtonLabel') }}
					</div>
					<div
						v-if="isActive(sub) && !sub.cancelRequestedAt && !isExpired(sub)"
						class="profile-subscriptions-profile-table-content-item profile-subscriptions-profile-action right"
						@click="$emit('toggle-cancel-sub-modal', sub.id)"
					>
						{{ $t('profile.subscription.cancelButtonLabel') }}
					</div>
					<div
						v-else-if="isActive(sub) && sub.cancelRequestedAt && !isExpired(sub)"
						class="profile-subscriptions-profile-table-content-item profile-subscriptions-profile-action-text right"
					>
						{{ $t('profile.subscription.cancelRequested') }}
					</div>
					<div v-else class="profile-subscriptions-profile-table-content-item actions" />
				</div>
			</div>
		</div>
		<div
			v-if="!hasBigParkingSubscription"
			:class="{ 'no-subscriptions': true }"
			class="profile-subscriptions-profile-table-content-row"
		>
			<router-link class="button profile-subscriptions-profile-cta" to="/subscription">
				{{ $t('profile.addSubscriptionLabel') }}
			</router-link>
		</div>
		<div
			v-for="(item, index) in proposals"
			:key="index"
			class="button orange profile-action-button proposal-cta"
			@click="$emit('toggle-proposal-modal', item.id)"
		>
			{{ $t('profile.proposal.cta') }} ({{ item.parking.code }})
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
import { date } from '@/helpers'
import {
	subscriptionStatusName,
	isExpired,
	subscriptionName,
	addressName,
} from '@/store/modules/subscription'
import { config, SUBSCRIPTION_STATES } from '@/config'
import InvoiceItemsService from '../../services/invoiceItems'

const stripe = window.Stripe(config.stripePublicKey)

export default {
	name: 'ProfileSubscriptions',
	components: {
		Loading,
	},
	props: {
		subscriptions: {
			type: Array,
			default: () => [],
		},
		proposals: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			// profileLocal: Object.assign({}, this.profile),
			invoiceUrl: `${config.apiUrl}/invoices/generate-pdf`,
			lastInvoiceID: null,
			lastPaidInvoiceItem: {},
		}
	},
	computed: {
		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
		}),
		...mapGetters('subscription', {
			subIsRetryableForPaymentLink: 'subIsRetryableForPaymentLink',
		}),
		...mapState({
			subscription: (state) => state.subscription.current,
			isLoading: (state) => state.subscription.isLoading,
		}),
	},
	mounted() {
		// Filter subscriptions that have paid invoices
		const subscriptionsWithPaidInvoices = this.subscriptions.filter((subscription) =>
			subscription.invoiceItems.some((item) => item.invoice.paid)
		)

		// Sort these subscriptions by the 'end' date (latest first)
		subscriptionsWithPaidInvoices.sort((a, b) => b.end - a.end)

		// Get the latest paid invoice
		const latestPaidInvoice = subscriptionsWithPaidInvoices

		if (latestPaidInvoice && latestPaidInvoice.length > 0) {
			this.lastInvoiceID = latestPaidInvoice
		}
	},

	methods: {
		...mapActions('subscription', {
			refreshSubscription: 'refreshSubscription',
			undoCancelRequestSubscription: 'undoCancelRequestSubscription',
		}),
		...mapMutations('subscription', {
			setLoading: 'setLoading',
		}),
		date,
		subscriptionName,
		addressName,
		subscriptionStatusName,
		isExpired,

		async doRefreshSubscription(subId) {
			await this.refreshSubscription({ subId })

			if (this.subIsRetryableForPaymentLink) {
				this.$router.push({
					name: 'subscription',
					query: {
						subId: this.subscription.id,
						proposal: this.subscription.proposalId,
					},
				})

				return true
			} else {
				const paymentIntentSecret = this.subscription.scaPaymentIntentSecret

				if (paymentIntentSecret) {
					this.setLoading(true)

					return stripe.confirmCardPayment(paymentIntentSecret).then((result) => {
						if (result.error) {
							this.flash(result.error.message, 'error') // maybe no flash message needed
							window.location.reload()
						} else {
							setTimeout(() => {
								window.location.reload()
							}, 5000)
						}
					})
				} else {
					return true
				}
			}
		},
		isPending(sub) {
			return sub.status === SUBSCRIPTION_STATES.pending
		},

		isActive(sub) {
			return sub.status === SUBSCRIPTION_STATES.active
		},
		lastPaidInvoice(subId) {
			const subscriptions = this.lastInvoiceID?.filter((invoice) => invoice.id === subId)
			if (subscriptions && subscriptions.length > 0) {
				const latestInvoiceItems = subscriptions[0].invoiceItems.filter((items) => items.type === 1)

				// Sort the invoice items by id in descending order and get the first one
				const latestInvoiceItem = latestInvoiceItems.sort((a, b) => b.id - a.id)[0]
				this.lastPaidInvoiceItem[subId] = latestInvoiceItem.id

				return true
			} else {
				return false
			}
		},
		async downloadInvoice(subId) {
			const response = await InvoiceItemsService.generatePdf(subId)
			const blob = new Blob([response], { type: 'application/pdf' })
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `invoice-${subId}.pdf`)
			document.body.appendChild(link)
			link.click()
			link.remove()
			window.URL.revokeObjectURL(url);
		},	
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/profile/index';
</style>
