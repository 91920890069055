// session.js

import graphqlClient from '../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'
import router from '@/router'
import { cloneDeep } from 'lodash/fp'
import SessionService from '../../services/sessions'
import GeneralService from '../../services/general'

export const BOURSE = { ID: 330, NIGHT: { START: 1, END: 18 } }
// Initial state
const initialState = () => ({
	sessionCreate: [],
	sessionStart: [],
	sessionQr2: [],
	sessionMdExt: [],
	sessionMdPickEnt: [],
	sessionMdPickExt: [],
	sessionStop: [],
	sessionEnd: [],
	sessionActive: [],
	userLiveSession: null,
	userSessions: [],
	isLoading: false,
	error: null,
	mandates: [],
	token: '',
})

const state = initialState()

// GraphQL Queries
const userSessionDetailQuery = gql`
	query SessionById($sessionByIdId: Int) {
		sessionById(id: $sessionByIdId) {
			bikes
			createdAt
			duration
			endTime
			id
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
			}
			parkingId
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
	}
`
const userSessionsQuery = gql`
	query UserSessions($limit: Int, $offset: Int, $userId: Int) {
		userSessions(limit: $limit, offset: $offset, userId: $userId) {
			bikes
			createdAt
			duration
			id
			endTime
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
			}
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
	}
`
const getAllMandates = gql`
	query GetAllMandates {
		getAllMandates {
			createdAt
			customerId
			default
			details {
				cardExpiryDate
				cardFingerprint
				cardHolder
				cardLabel
				cardNumber
			}
			id
			mandateReference
			method
			mode
			signatureDate
			status
		}
	}
`
const userLiveSessionQuery = gql`
	query UserLiveSession($userId: Int) {
		userLiveSession(userId: $userId) {
			bikes
			createdAt
			duration
			endTime
			id
			parking {
				id
				code
				name
				legacyAddress {
					en
					fr
					nl
				}
				sessionConfig {
					id
					parkingId
					perHourCost
					subscriptionFreeHours
				}
			}
			paymentMethod
			sessionId
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
			startTime
			totalAmountPaid
			userId
		}
		serverTime
	}
`
// GraphQL mutations
const startNewSessionMutation = gql`
	mutation StartNewSession($props: SessionProps) {
		startNewSession(props: $props) {
			session {
				id
				userId
				parkingId
				sessionQRCodes {
					id
					userSessionId
					qrType
					qrCode
					used
					usage
					usedAt
					expiresIn
				}
				bikes
				sessionId
				paymentMethod
				startTime
				endTime
				duration
				totalAmountPaid
				createdAt
			}
		}
	}
`

const generateQrCode = gql`
	mutation GenerateQrCode($sessionId: Int, $codeType: String) {
		generateQrCode(sessionId: $sessionId, codeType: $codeType) {
			id
			userId
			parkingId
			sessionQRCodes {
				id
				userSessionId
				qrType
				qrCode
				used
				usage
				usedAt
				expiresIn
			}
			bikes
			sessionId
			paymentMethod
			startTime
			endTime
			duration
			totalAmountPaid
			createdAt
		}
	}
`

// GraphQL mutations
const cancelCurrentSessionMutation = gql`
	mutation cancelSession($sessionId: Int) {
		cancelSession(sessionId: $sessionId) {
			sessionId
		}
	}
`

// GraphQL mutations
const updateQrCodeUsageMutation = gql`
	mutation updateQrCodeUsage($props: QrUsageProps) {
		updateQrCodeUsage(props: $props) {
			rfid
			parkingId
		}
	}
`

const endSessionMutation = gql`
	mutation SessionEnd($sessionId: Int) {
		sessionEnd(sessionId: $sessionId) {
			payment {
				status
				description
			}
			session {
				id
				userId
				parkingId
				sessionQRCodes {
					id
					userSessionId
					qrType
					qrCode
					used
					usage
					usedAt
					expiresIn
				}
				bikes
				sessionId
				paymentMethod
				startTime
				endTime
				duration
				totalAmountPaid
				createdAt
			}
		}
	}
`
const updateSessionMutation = gql`
	mutation SessionUpdate($props: SessionProps) {
		sessionUpdate(props: $props)
	}
`

// Actions
const actions = {
	async startNewSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: startNewSessionMutation,
			// 	variables: { props: sessionProps },
			// })
			const response = await SessionService.createNewSession(sessionProps)
			const session = response.data
			commit('setSessionId', session.sessionId)
			commit('addSession', session)
			return session
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},

	async generateQrCode({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			// const mutationResponse = await graphqlClient.mutate({
			// 	mutation: generateQrCode,
			// 	variables: { sessionId: sessionProps.sessionId, codeType: sessionProps.codeType },
			// })
			const mutationResponse = await SessionService.generateSessionQRCode(sessionProps.sessionId, {
				codeType: sessionProps.codeType,
			})
			const sessionData = mutationResponse.data
			const enterQr = sessionData.sessionQRCodes.filter((b) => b.qrType === 'ENT')
			commit('resetState')
			if (sessionProps.codeType === 'ENT') {
				sessionData.sessionQRCodes = enterQr
				commit('stopSession', sessionData)
			} else {
				return sessionData
			}
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},

	async cancelCurrentSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: cancelCurrentSessionMutation,
			// 	variables: { sessionId: sessionProps },
			// })
			const response = await SessionService.cancelCurrentSession(sessionProps)
			return response.data
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			return { status: 'failed' }
		} finally {
			commit('setLoading', false)
		}
	},

	async updateQrCodeUsage({ commit }, QrUsageProps) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: updateQrCodeUsageMutation,
			// 	variables: { props: QrUsageProps },
			// })
			const response = await SessionService.updateQRUsage({ rfid: QrUsageProps.rfid })
			// const { session } = response.data.startNewSession
			// commit('setSessionId', session.sessionId)
			// commit('addSession', session)
			return response
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
		} finally {
			commit('setLoading', false)
		}
	},

	async endSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: endSessionMutation,
			// 	variables: { sessionId: sessionProps },
			// })
			const response = await SessionService.endSession(sessionProps)
			const { session, payment } = response
			return payment
			//const session = response.data.startNewSession.session
			//commit('setSessionId', session.sessionId)
			//commit('addSession', session)
			//return session
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			return { status: 'failed' }
		} finally {
			commit('setLoading', false)
		}
	},

	async getSessionById({ commit, dispatch }, sessionProps) {
		commit('setLoading', true)

		try {
			// const queryResponse = await graphqlClient.query({
			// 	query: gql`
			// 		query SessionById($sessionByIdId: Int) {
			// 			sessionById(id: $sessionByIdId) {
			// 				id
			// 				userId
			// 				parking {
			// 					id
			// 					code
			// 					name
			// 					legacyAddress {
			// 						en
			// 						fr
			// 						nl
			// 					}
			// 					sessionConfig {
			// 						id
			// 						parkingId
			// 						perHourCost
			// 						subscriptionFreeHours
			// 					}
			// 				}
			// 				parkingId
			// 				sessionQRCodes {
			// 					id
			// 					userSessionId
			// 					qrType
			// 					qrCode
			// 					used
			// 					usage
			// 					usedAt
			// 					expiresIn
			// 				}
			// 				bikes
			// 				sessionId
			// 				paymentMethod
			// 				startTime
			// 				endTime
			// 				active
			// 				duration
			// 				totalAmountPaid
			// 				createdAt
			// 			}
			// 			serverTime
			// 		}
			// 	`,
			// 	variables: { sessionByIdId: sessionProps.sessionId },
			// 	fetchPolicy: 'no-cache',
			// })

			// const queryResponse = await SessionService.getSessionById(sessionProps.sessionId)
			// const serverTime =await GeneralService.getServerTime()
			const [queryResponse, serverTime] = await Promise.all([
				SessionService.getSessionById(sessionProps.sessionId),
				GeneralService.getServerTime(),
			])
			commit('resetState')
			const sessionData = queryResponse.data
			// const serverTime = queryResponse.data.serverTime
			commit('setServerTime', serverTime, { root: true })
			// function to check if QR codes are usable
			const isQrUsable = (qr) => qr.length > 0 && qr[0].used < qr[0].usage
			const isQrExhausted = (qr) => qr.length > 0 && qr[0].used === qr[0].usage
			const isExpired = (expiresIn, usedAt) => {
				// Calculate expiry time in milliseconds
				const expiryTime = usedAt + expiresIn * 60 * 1000
				// Check if the current server time is greater than the expiry time
				return serverTime > expiryTime
			}
			const isSessionExipred = () => {
				if (!sessionData.active) {
					const sessionId = localStorage.getItem('sessionId')
					if (sessionId) {
						console.log('isSessionExipired')
						localStorage.removeItem('sessionId')
						localStorage.removeItem('sessionBikes')
						localStorage.removeItem('mainDoorPickupStartTime')
						localStorage.removeItem('qrTimeout')
						router.push({ name: 'howitworks' })
						return true
					}
				} else {
					return false
				}
			}
			// filter QR codes by types
			const qrTypes = {
				mainDoorEnterQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDENT'),
				mainDoorExtQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDEXT'),
				mainDoorPickupEnterQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDPENT'),
				mainDoorPickupExitQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDPEXT'),
				firstEnterQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'FENT'),
				firstExitQr: sessionData.sessionQRCodes.filter((a) => a.qrType === 'FEXT'),
				enterQr: sessionData.sessionQRCodes.filter((c) => c.qrType === 'ENT'),
				exitQr: sessionData.sessionQRCodes.filter((c) => c.qrType === 'EXT'),
			}
			const sessionQrExipre = localStorage.getItem('qrTimeout')

			// parking main door enter
			if (isQrUsable(qrTypes.mainDoorEnterQr) && qrTypes.firstEnterQr.length === 0) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.mainDoorEnterQr
					commit('mainDoorEnter', sessionData)
					return
				}
			}
			// parking enter if main door enter qr exits and skip
			if (isQrExhausted(qrTypes.mainDoorEnterQr) && qrTypes.firstEnterQr.length === 0) {
				if (!isSessionExipred()) {
					const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'FENT' }
					const response = await dispatch('generateQrCode', sessionnewProps)
					if (router.history.current.name !== 'SessionStartQr') {
						setTimeout(() => {
							router.push({ name: 'SessionStartQr' })
						}, 3000)
					}
					return
				}
			}

			// parking enter if main door qr not exits
			if (isQrUsable(qrTypes.firstEnterQr)) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.firstEnterQr
					commit('addSession', sessionData)
					if (router.history.current.name !== 'SessionStartQr') {
						router.push({ name: 'SessionStartQr' })
					}
					return
				}
			}

			// generate qr for parking exit

			if (isQrExhausted(qrTypes.firstEnterQr) && qrTypes.firstExitQr.length === 0) {
				const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'FEXT' }
				const response = await dispatch('generateQrCode', sessionnewProps)
				if (router.history.current.name !== 'Qr2') {
					setTimeout(() => {
						router.push({ name: 'Qr2' })
					}, 3000)
				}
			}

			// parking exit
			if (isQrUsable(qrTypes.firstExitQr)) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.firstExitQr
					commit('firstParkingExit', sessionData)
					if (router.history.current.name !== 'Qr2') {
						router.push({ name: 'Qr2' })
					}
					return
				}
			}

			// parking main door exit qr for night time Or active session page
			if (
				isQrExhausted(qrTypes.firstExitQr) &&
				qrTypes.mainDoorExtQr.length === 0 &&
				qrTypes.enterQr.length === 0
			) {
				if (!isSessionExipred()) {
					const currentTime = new Date()
					const hours = currentTime.getHours()
					if (
						sessionData.parkingId == BOURSE.ID &&
						hours >= BOURSE.NIGHT.START &&
						hours <= BOURSE.NIGHT.END
					) {
						const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'MDEXT' }
						const response = await dispatch('generateQrCode', sessionnewProps)
						if (router.history.current.name !== 'MainDoorExtQr') {
							setTimeout(() => {
								router.push({ name: 'MainDoorExtQr' })
							}, 3000)
						}
					} else {
						commit('activeSession', sessionData)
						if (router.history.current.name !== 'HowSessionStart')
							router.push({ name: 'HowSessionStart' })
					}
					return
				}
			}

			// active session page if its day time
			if (
				isQrExhausted(qrTypes.mainDoorExtQr) &&
				qrTypes.enterQr.length === 0 &&
				qrTypes.mainDoorPickupEnterQr.length === 0
			) {
				if (!isSessionExipred()) {
					commit('activeSession', sessionData)
					if (router.history.current.name !== 'HowSessionStart')
						router.push({ name: 'HowSessionStart' })
					return
				}
			}
			// parking main door exit
			if (isQrUsable(qrTypes.mainDoorExtQr) && qrTypes.enterQr.length === 0) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.mainDoorExtQr
					commit('mainDoorExit', sessionData)
					return
				}
			}
			// console.log('a')
			// check main door exit is used and pickup main door length 0 or is used or expired
			if (
				isQrExhausted(qrTypes.mainDoorExtQr) &&
				(qrTypes.mainDoorPickupEnterQr.length === 0 ||
					(qrTypes.mainDoorPickupEnterQr.length > 0 &&
						isQrUsable([qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1]]) &&
						isExpired(
							10,
							qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1].usedAt
						))) &&
				qrTypes.enterQr.length === 0
			) {
				if (!isSessionExipred()) {
					commit('activeSession', sessionData)
					if (router.history.current.name !== 'HowSessionStart')
						router.push({ name: 'HowSessionStart' })
					return
				}
			}
			// console.log('b')
			// check if maindoor enter is used and qr 2 is not used but expired
			if (
				qrTypes.mainDoorPickupEnterQr.length > 0 &&
				isQrExhausted([qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1]]) &&
				qrTypes.enterQr.length > 0 &&
				isQrUsable([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
				qrTypes.mainDoorPickupEnterQr.length > 0 &&
				isExpired(
					10,
					qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1].usedAt
				)
			) {
				if (!isSessionExipred()) {
					commit('activeSession', sessionData)
					if (router.history.current.name !== 'HowSessionStart')
						router.push({ name: 'HowSessionStart' })
					return
				}
			}
			// console.log('c')
			// parking main door enter to pickup bike
			if (
				qrTypes.mainDoorPickupEnterQr.length > 0 &&
				isQrUsable([qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1]]) &&
				!isExpired(
					10,
					qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1].usedAt
				) &&
				(qrTypes.enterQr.length === 0 ||
					isExpired(
						10,
						qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 2].usedAt
					))
			) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.mainDoorPickupEnterQr
					commit('mainDoorEnter', sessionData)
					if (router.history.current.name !== 'MainDoorQr') {
						router.push({ name: 'MainDoorQr' })
					}
					return
				}
			}
			// console.log('d')
			// parking enter and exit if main door enter qr exits and skip
			if (
				(qrTypes.mainDoorPickupEnterQr.length > 0 &&
					isQrExhausted([
						qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1],
					]) &&
					qrTypes.enterQr.length === 0) ||
				(qrTypes.mainDoorPickupEnterQr.length > 0 &&
					isQrUsable([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
					!isExpired(
						10,
						qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1].usedAt
					) &&
					isExpired(10, qrTypes.enterQr[qrTypes.enterQr.length - 1].usedAt))
			) {
				if (!isSessionExipred()) {
					try {
						const responseEndSession = await dispatch('endSession', sessionData.id)
						if (responseEndSession.status === 'failed') {
							console.log('payment failed')
							this.error(this.errorMessage)
							setTimeout(() => {
								router.push({ name: 'HowItPay' })
							}, 3000)
						} else if (responseEndSession.status === 'paid') {
							console.log('payment success session js')
							// Call the action with the provided sessionProps
							const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'ENT' }
							// Handle the response data here
							const response = await dispatch('generateQrCode', sessionnewProps)
							const mainDoorPickupQrTime = localStorage.getItem('mainDoorPickupStartTime')
							if (!mainDoorPickupQrTime) {
								localStorage.setItem(
									'mainDoorPickupStartTime',
									qrTypes.mainDoorPickupEnterQr[qrTypes.mainDoorPickupEnterQr.length - 1].usedAt
								)
							}
							if (router.history.current.name !== 'EnteringQr') {
								setTimeout(() => {
									router.push({ name: 'EnteringQr' })
								}, 3000)
							}
						}
					} catch (error) {
						commit('setError', extractGraphqlError(error.message))
					}
					return
				}
			}
			// console.log('e')
			// enter again parking to collect bike
			if (
				qrTypes.enterQr.length > 0 &&
				isQrUsable([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
				qrTypes.exitQr.length === 0
			) {
				if (!isSessionExipred()) {
					if (sessionQrExipre === 'true') {
						commit('activeSession', sessionData)
						if (router.history.current.name !== 'HowSessionStart')
							router.push({ name: 'HowSessionStart' })
						return
					} else {
						sessionData.sessionQRCodes = qrTypes.enterQr
						commit('stopSession', sessionData)
						if (router.history.current.name !== 'EnteringQr') {
							router.push({ name: 'EnteringQr' })
						}
						return
					}
				}
			}
			// enter again parking to collect bike
			if (
				qrTypes.enterQr.length > 0 &&
				isQrUsable([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
				qrTypes.exitQr.length === 0
			) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.enterQr
					commit('stopSession', sessionData)
					if (router.history.current.name !== 'EnteringQr') {
						router.push({ name: 'EnteringQr' })
					}
					return
				}
			}
			// skip enter again parking qr
			if (
				qrTypes.enterQr.length > 0 &&
				isQrExhausted([qrTypes.enterQr[qrTypes.enterQr.length - 1]]) &&
				qrTypes.exitQr.length === 0
			) {
				if (!isSessionExipred()) {
					const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'EXT' }
					const response = await dispatch('generateQrCode', sessionnewProps)
					if (response) {
						if (router.history.current.name !== 'SessionEndQr') {
							setTimeout(() => {
								router.push({ name: 'SessionEndQr' })
							}, 3000)
						}
					}
					return
				}
			}
			// exit from parking qr
			if (isQrUsable(qrTypes.exitQr)) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.exitQr
					commit('endSession', sessionData)
					if (router.history.current.name !== 'SessionEndQr') router.push({ name: 'SessionEndQr' })
					return
				}
			}
			// summary page after exit from parking
			if (isQrExhausted(qrTypes.exitQr) && qrTypes.mainDoorPickupExitQr.length === 0) {
				const currentTime = new Date()
				const hours = currentTime.getHours()
				if (
					sessionData.parkingId == BOURSE.ID &&
					hours >= BOURSE.NIGHT.START &&
					hours <= BOURSE.NIGHT.END
				) {
					const sessionnewProps = { sessionId: sessionProps.sessionId, codeType: 'MDPEXT' }
					const response = await dispatch('generateQrCode', sessionnewProps)
					if (response) {
						if (router.history.current.name !== 'MainDoorExtQr')
							setTimeout(() => {
								router.push({ name: 'MainDoorExtQr' })
							}, 3000)
					}
					return
				}
				router.push(`/session/${sessionProps.sessionId}/thankyou`)
				localStorage.removeItem('sessionId')
				localStorage.removeItem('sessionBikes')
				localStorage.removeItem('mainDoorPickupStartTime')
				return
			}
			// main door final exit
			if (isQrUsable(qrTypes.mainDoorPickupExitQr)) {
				if (!isSessionExipred()) {
					sessionData.sessionQRCodes = qrTypes.mainDoorPickupExitQr
					commit('mainDoorExit', sessionData)
					if (router.history.current.name !== 'MainDoorExtQr')
						router.push({ name: 'MainDoorExtQr' })
					return
				}
			}

			//summary page if main door final exit is used
			if (isQrExhausted(qrTypes.mainDoorPickupExitQr)) {
				router.push(`/session/${sessionProps.sessionId}/thankyou`)
				localStorage.removeItem('sessionId')
				localStorage.removeItem('sessionBikes')
				localStorage.removeItem('mainDoorPickupStartTime')
				return
			}
		} catch (error) {
			commit('setError', extractGraphqlError(error.message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},

	async getUserLiveSession({ commit }, userId) {
		commit('setLoading', true)
		try {
			// const queryResponse = await graphqlClient.query({
			// 	query: userLiveSessionQuery,
			// 	variables: { userId },
			// 	fetchPolicy: 'no-cache',
			// })
			const [userLiveSessionData, serverTime] = await Promise.all([
				SessionService.getLiveSessionByUserId(userId),
				GeneralService.getServerTime(),
			])
			commit('userLiveSession', userLiveSessionData)
			commit('setServerTime', serverTime, { root: true })
			return userLiveSessionData
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},
	async getUserSessions({ commit }, userId) {
		commit('setLoading', true)
		try {
			// const mutationResponse = await graphqlClient.query({
			// 	query: userSessionsQuery,
			// 	variables: { userId },
			// 	fetchPolicy: 'no-cache',
			// })
			const userSessionsData = await SessionService.getSessionsByUserId(userId)
			commit('userSessions', userSessionsData)
			return userSessionsData
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},
	async getUserSessionDetail({ commit }, sessionByIdId) {
		commit('setLoading', true)
		try {
			// const mutationResponse = await graphqlClient.query({
			// 	query: userSessionDetailQuery,
			// 	variables: { sessionByIdId },
			// 	fetchPolicy: 'no-cache',
			// })
			const userSessionData = await SessionService.getSessionById(sessionByIdId)
			// const userSessionData = mutationResponse.data.sessionById
			// commit('userSessionDetail', userSessionData);
			return userSessionData.data
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},
	getLiveSessionDetailLink({ commit, state }) {
		let activeSessionDetailLink = 'HowSessionStart'
		if (state.userLiveSession?.id) {
			const sessionData = cloneDeep(state.userLiveSession)
			const mainDoorEnterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDENT')
			const firstEnterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'FENT')
			const firstExitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'FEXT')
			const mainDoorExtQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDEXT')
			const mainDoorPickupEnterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDPENT')
			const enterQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'ENT')
			const exitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'EXT')
			const mainDoorPickupExitQr = sessionData.sessionQRCodes.filter((a) => a.qrType === 'MDPEXT')

			if (mainDoorEnterQr.length > 0 && mainDoorEnterQr[0].used < mainDoorEnterQr[0].usage) {
				sessionData.sessionQRCodes = mainDoorEnterQr
				//commit('session/mainDoorEnter', sessionData, { root: true })
				activeSessionDetailLink = 'MainDoorQr'
			} else if (firstEnterQr[0].used < firstEnterQr[0].usage) {
				sessionData.sessionQRCodes = firstEnterQr
				//commit('session/addSession', sessionData, { root: true })
				activeSessionDetailLink = 'SessionStartQr'
			} else if (firstExitQr[0].used < firstExitQr[0].usage) {
				sessionData.sessionQRCodes = firstExitQr
				//commit('session/addSession', sessionData, { root: true })
				activeSessionDetailLink = 'Qr2'
			} else if (mainDoorExtQr.length > 0 && mainDoorExtQr[0].used < mainDoorExtQr[0].usage) {
				sessionData.sessionQRCodes = mainDoorExtQr
				//commit('session/mainDoorExit', sessionData, { root: true })
				activeSessionDetailLink = 'MainDoorExtQr'
			} else if (firstExitQr[0].used === firstExitQr[0].usage && enterQr.length === 0) {
				//commit('session/activeSession', sessionData, { root: true })
				activeSessionDetailLink = 'HowSessionStart'
			} else if (
				mainDoorPickupEnterQr.length > 0 &&
				mainDoorPickupEnterQr[mainDoorPickupEnterQr.length - 1].used <
					mainDoorPickupEnterQr[mainDoorPickupEnterQr.length - 1].usage
			) {
				sessionData.sessionQRCodes = mainDoorPickupEnterQr
				//commit('session/mainDoorEnter', sessionData, { root: true })
				activeSessionDetailLink = 'MainDoorQr'
			} else if (
				enterQr.length > 0 &&
				enterQr[enterQr.length - 1].used < enterQr[enterQr.length - 1].usage
			) {
				sessionData.sessionQRCodes = enterQr
				//commit('session/stopSession', sessionData, { root: true })
				activeSessionDetailLink = 'EnteringQr'
			} else if (exitQr.length > 0 && exitQr[0].used < exitQr[0].usage) {
				sessionData.sessionQRCodes = exitQr
				//commit('session/endSession', sessionData, { root: true })
				activeSessionDetailLink = 'SessionEndQr'
			} else if (
				mainDoorPickupExitQr.length > 0 &&
				mainDoorPickupExitQr[0].used < mainDoorPickupExitQr[0].usage
			) {
				sessionData.sessionQRCodes = mainDoorPickupExitQr
				//commit('session/mainDoorExit', sessionData, { root: true })
				activeSessionDetailLink = 'MainDoorExtQr'
			} else {
				console.log('else condition')
			}
			// set active session id into local storage
			localStorage.setItem('sessionId', sessionData.sessionId)
		}
		return activeSessionDetailLink
	},
	async getAllMandates({ commit }) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.query({
			// 	query: getAllMandates,
			// 	fetchPolicy: 'no-cache',
			// })
			const response = await SessionService.getAllMandates()
			commit('setLoading', false)
			commit('setMandates', response)
		} catch (e) {
			// logger.error('catching error in update payment method', e)
			commit('setLoading', false)
			commit('setError', extractGraphqlError(e.message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		}
	},
	async updateSession({ commit }, sessionProps) {
		commit('setLoading', true)
		try {
			// const response = await graphqlClient.mutate({
			// 	mutation: updateSessionMutation,
			// 	variables: { props: sessionProps },
			// })
			const response = await SessionService.updateSession(sessionProps)
			const res = response.data.sessionUpdate
			return res
		} catch ({ message }) {
			commit('setError', extractGraphqlError(message))
			if (router.history.current.name !== 'PageNotFound') {
				router.push({ name: 'PageNotFound' })
			}
		} finally {
			commit('setLoading', false)
		}
	},
	/**
	 * Calculate session amount from start time till the provided end time. To be used for active session as its not ended yet.
	 */
	getSessionAmountTill({ commit, state }, endTime) {
		if (!state.userLiveSession.startTimeInMs) {
			return 0
		}
		const noOfBikes = state.userLiveSession.bikes || 1
		let diffMs = Math.abs(endTime - state.userLiveSession.startTimeInMs)
		const diffDays = Math.floor(diffMs / 86400000) // days
		const diffHrs = Math.floor((diffMs % 86400000) / 3600000) // hours
		const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000) // minutes
		const totalHours = Math.ceil(diffDays * 24 + diffHrs + diffMins / 60)
		const parkingConfig = state.userLiveSession.sessionParking.parkingSessionConfigs[0]
		// const parkingConfig = state.userLiveSession.parking.sessionConfig
		const totalDaysToCharge = totalHours > 0 ? Math.ceil(totalHours / 24) : 0
		let totalAmount =
			totalHours > parkingConfig.subscriptionFreeHours
				? (parkingConfig.perHourCost * totalDaysToCharge * noOfBikes).toFixed(2)
				: 0
		return totalAmount
	},
	resetState({ commit }) {
		commit('resetState')
	},
}

// Mutations
const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},

	mainDoorEnter(state, session) {
		state.sessionCreate.push(session)
		state.error = null
	},
	addSession(state, session) {
		state.sessionStart.push(session)
		state.error = null
	},
	firstParkingExit(state, session) {
		state.sessionQr2.push(session)
		state.error = null
	},
	mainDoorExit(state, session) {
		state.sessionMdExt.push(session)
		state.error = null
	},
	stopSession(state, session) {
		state.sessionStop.push(session)
		state.error = null
	},
	endSession(state, session) {
		state.sessionEnd.push(session)
		state.error = null
	},
	activeSession(state, session) {
		state.sessionActive.push(session)
		state.error = null
	},
	userLiveSession(state, session) {
		state.userLiveSession = session
		state.error = null
	},
	userSessions(state, sessions) {
		state.userSessions = sessions
		state.error = null
	},
	setSessionId(state, sessionId) {
		localStorage.setItem('sessionId', sessionId)
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setError(state, error) {
		state.error = error
	},
	setMandates(state, mandates) {
		state.mandates = mandates
		state.error = null
	},
	setToken(state, token) {
		state.token = token
		state.error = null
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
