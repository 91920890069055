<template>
	<div class="subscription-wrapper">
		<div>
			<h1 class="title">
				{{
					$t(`subscription.parkingTypes.${data.subType.toLowerCase()}.titleStep1`, {
						parking: data.parking.code,
					})
				}}
			</h1>
			<p
				class="text subscription"
				v-html="
					$t(`subscription.parkingTypes.${data.subType.toLowerCase()}.infoStep1`, {
						parking: data.parking.name,
					})
				"
			/>
			<p>
				<a :href="$t('general.navigationLinks.terms')" target="_blank" class="link">{{
					$t('general.navigation.terms')
				}}</a>
			</p>
		</div>
		<Loading v-if="!data.isLoaded" />
		<form class="subscription-form" @submit.prevent>
			<div v-if="showMobib" class="subscription-mobib-container">
				<MobibInput
					v-if="!data.mobib"
					:mobib.sync="profileLocal.mobib"
					:mobibPresent="Boolean(data.mobib)"
					:message="$t('profile.mobibInfo')"
					errorTag="mobib"
					class="subscription-mobib-box"
				/>
				<div v-else class="subscription-mobib-info">
					<p class="subscription-mobib-info-text">
						{{ $t('subscription.currentMobib') }}
					</p>
					<p class="subscription-mobib-number">
						{{ data.mobib }}
					</p>
					<router-link to="/profile?edit=true" class="subscription-mobib-button button blue">
						{{ $t('subscription.changeMobibLink') }}
					</router-link>
				</div>
				<p v-show="errors.has('mobib')" class="input-error">
					{{ errors.first('mobib') }}
				</p>
			</div>
			<div v-if="isAirKey">
				{{ $t('subscription.infoAirKey') }}
				<a :href="$t('subscription.airKeyIosLink')" class="link" target="_blank"> IOS </a> /
				<a :href="$t('subscription.airKeyAndroidLink')" class="link" target="_blank"> ANDROID </a>
			</div>
			<Checkbox
				v-if="allowBadgeAccessOverride"
				v-model="useBadgeAccess"
				class="profile-checkbox"
				name="useBadgeAccess"
			>
				<span v-if="isMobib" slot="label" class="checkbox-text text">{{
					$t('subscription.noMobibAction')
				}}</span>
				<span v-if="isAirKey" slot="label" class="checkbox-text text">{{
					$t('subscription.noAirkeyAction')
				}}</span>
			</Checkbox>
			<div v-if="(!isBePark && !isBigParkingSub && !data.parking.mobib) || useBadgeAccess">
				<p class="text subscription">{{ $t('subscription.infoNoMobib') }}</p>
			</div>
			<div
				v-if="currentUser.company && !currentUser.company.vatNumber"
				class="warning-company-info"
			>
				{{ $t('subscription.companyInfoWarning') }}
				<router-link to="/profile?edit=true" class="link warning-company-info-link">
					{{ $t('subscription.companyFillInLink') }}
				</router-link>
			</div>
			<button
				:disabled="errors.any() || !allowNext"
				:class="{ disabled: errors.any() || !allowNext }"
				class="button blue subscription-form-next-button"
				@click="nextStep"
			>
				{{ $t('subscription.startLabel') }}
			</button>
		</form>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import MobibInput from '@/components/form/MobibInput.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import { mapState, mapActions } from 'vuex'

export default {
	name: 'Step1',
	components: {
		Loading,
		MobibInput,
		Checkbox,
	},
	props: {
		data: {
			type: Object,
			required: true,
			default: () => {},
		},
		deposit: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			mobib: null,
			profileLocal: {
				mobib: '',
			},
			useBadgeAccess: false,
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
		}),
		hasMobib() {
			return this.profileLocal.mobib || (this.currentUser || []).mobib
		},
		allowNext() {
			return this.useBadgeAccess || (this.showMobib ? this.hasMobib : true)
		},
		isAirKey() {
			return this.data.parking.isAirKey
		},
		isBePark() {
			return this.data.parking.isBePark
		},
		isMobib() {
			return this.data.parking.isMobib
		},
		showMobib() {
			return this.isBigParkingSub || this.isMobib
		},
		isBigParkingSub() {
			return !this.data.parking.id
		},
		useMobib() {
			return this.useBadgeAccess ? false : this.showMobib
		},
		needDeposit() {
			if (this.isBePark) {
				return false
			}

			return this.useBadgeAccess ? true : !(this.showMobib || this.isAirKey)
		},
		allowBadgeAccessOverride() {
			return this.data.parking.badgeAccess && (this.data.parking.mobib || this.isAirKey)
		},
	},
	methods: {
		...mapActions({
			// setUseMobib: 'subscription/setUseMobib',
		}),
		nextStep() {
			// this.setUseMobib(this.useMobib)
			this.$emit('update:deposit', this.needDeposit)
			this.$emit('next-step', this.profileLocal.mobib)
		},
	},
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/pages/subscription/step1';

.warning-company-info {
	border: 1px solid $blue;
	color: $red;
	padding: 15px;
	text-align: center;
}
</style>
